export class RecipientData {
    failed: number;
    initialized: number;
    total: number;
    subject: string;
    method: string;
    notificationTypeCode: string;
    filterName: string;
    sourceName: string;
    includeEmail: boolean;
    recipientList: RecipientList[];
    sentDateTime: string;
}

export class RecipientList {
    employeeId: string;
    name: string;
    companyCode: string;
    status: string;
    receivedVia: string;
}