import {Component, OnInit, ViewChild, Input, OnDestroy, Output, EventEmitter} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Type } from '../../models/notification.model';
import readXlsxFile from 'read-excel-file';
import { MatStepper, MatDialogRef, MatDialog } from '@angular/material';
import { NotificationService } from '../../../services/notification.service';
import { SpinnerOverlayService } from '../../../../shared/services/spinner-overlay.service';
import { RecipientFiltersRow } from '../../models/recipient-filters.model';
import { RecipientFiltersService } from '../../../services/recipient-filters.service';
import { Subject, BehaviorSubject} from "rxjs";
import { takeUntil} from "rxjs/operators";
import { ValidateCorporateEmailDirective} from "../../../validators/validate-corporate-email.directive";
import { NotificationImportInfoComponent } from './notification-import-info/notification-import-info.component';
import { FilterCriteriaDialogComponent } from '../../../components/shared/filter-criteria-dialog/filter-criteria-dialog.component';
import { PreviewRecipientsDialogComponent } from '../../../../../app/core/components/shared/preview-recipients-dialog/preview-recipients-dialog.component';

@Component({
    selector: 'app-notification-recipients',
    templateUrl: './notification-recipients.component.html',
    styleUrls: ['./notification-recipients.component.scss']
})
export class NotificationRecipientsComponent implements OnInit, OnDestroy {

    notificationRecipientsFormGroup: FormGroup;
    @Input() stepper: MatStepper;
    uploadCount = 0;
    validCount = 0;
    dialogRefFilterCriteria: MatDialogRef<FilterCriteriaDialogComponent>;
    dialogRefPreviewRecipients: MatDialogRef<PreviewRecipientsDialogComponent>;
    activeCountForImprt: number = 0;
    totalCountForImprt: number = 0;
    activeCountForExtfl: number = 0;
    totalCountForExtfl: number = 0;
    displayCountsMessageForImprt: boolean = false;
    displayCountsMessageForExtfl: boolean = false;    
    recipientListSourceFlag: boolean = false;
    warningRecipientListChange: string = null;
    notificationType: string = '';
    existingFilterId: number = 0;
    private fileToUpload: object;
    existingFilters: RecipientFiltersRow[];
    dialogImportInfo: MatDialogRef<NotificationImportInfoComponent>;
    // Private
    private _unsubscribeAll: Subject<any>;    

    recipientListSource: Type[] = [
        { code: "IMPRT", description: "Import List" },
        { code: "EXTFL", description: "Existing Filter" }
        //,{ code: "NEWFL", description: "Create New Filter" }
    ]

  constructor(private fb: FormBuilder,
              private notificationService: NotificationService,
              private recipientFiltersService: RecipientFiltersService,
              private dialog: MatDialog, 
              private spinner: SpinnerOverlayService) {
    this._unsubscribeAll = new Subject();
    this.notificationRecipientsFormGroup = this.fb.group({
      recipientListSource: [null,Validators.required],
      existingFilters: [null],
      fileName: [null],
      fileInput: [null],
			filterName: [null],
			ccList: [null],
      corporateEmailAddressList: [null,
        {validators: ValidateCorporateEmailDirective(new RegExp(".*(@ets.org)$")), updateOn: 'blur'}],
      uploadCount:[0],
      validCount: [0]      
    });
   }

  ngOnInit() {
    this.recipientFiltersService.getExistingFilters()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        if(data) {
          this.existingFilters = data;
        }
      }, error =>{
        // console.log(error);
      });    
    this.notificationService.previewRecipientsBS
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        data => {
          if(data) {
            this.displayCountsMessageForImprt = true;
            this.activeCountForImprt = data.activeCount;
            this.totalCountForImprt = data.totalCount;
          }
        }, error =>{
          // console.log(error);
        });
    this.recipientFiltersService.previewRecipientsBS
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        data => {
          if(data) {
            this.displayCountsMessageForExtfl = true;
            this.activeCountForExtfl = data.activeCount;
            this.totalCountForExtfl = data.totalCount;
          }
        }, error =>{
          // console.log(error);
        });
    this.notificationService.currentNotificationBS
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
      data => {
        if (data && data.type) {
          this.notificationType = data.type.code;          
        }
        if (data && data.recipients) {
          this.notificationRecipientsFormGroup.patchValue(data.recipients);
        }
      }, error =>{
        // console.log(error);
      }
    );    
  }

  ngOnDestroy(): void {
    if(this._unsubscribeAll){
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
    }
  }

  displayWith(obj?: any): string | undefined {
    return obj ? obj.name : undefined;
  }

  async save() {            
      this.warningRecipientListChange = null;
      let proceedWithSave : boolean = true;
      if (this.notificationRecipientsFormGroup.valid) {
        if (this.notificationRecipientsFormGroup.value.recipientListSource.code == 'EXTFL') {
          if (this.notificationRecipientsFormGroup.value.existingFilters == null) {
            this.notificationRecipientsFormGroup.get('existingFilters').setErrors({'required':true});
            proceedWithSave = false;
          }
        } else if (this.notificationRecipientsFormGroup.value.recipientListSource.code == 'IMPRT') {
          if (this.notificationRecipientsFormGroup.value.fileName ==  null) {      
            this.notificationRecipientsFormGroup.get('fileName').setErrors({'required':true});
            proceedWithSave = false;
          }
        }
        
        if (proceedWithSave) {
          this.notificationService.currentNotification.step = 3;
          this.notificationService.currentNotification.recipients = this.notificationRecipientsFormGroup.value;                
          
          if (this.notificationService.currentNotification.recipients.recipientListSource.code === 'IMPRT'){
            if (this.notificationService.currentNotification.recipients) {
              this.notificationService.currentNotification.recipients.existingFilters = null;
            }
          }
          if (this.notificationService.currentNotification.recipients.recipientListSource.code === 'EXTFL'){
            this.notificationService.currentNotification.filterCriteria = { filterId: this.existingFilterId };
          }

          this.spinner.show();
          const result = await this.notificationService.saveNotification();
          this.spinner.hide();
          if (result) {
            //  save back to currentNotification
            this.notificationService.currentNotification = result;
            this.notificationService.currentNotificationBS.next(result);
            this.stepper.next();
          }
        }          
      } else {
          this.validateAllFormFields(this.notificationRecipientsFormGroup);
      }
  }

  isValidFile(file: string) {
    return (file.endsWith('.csv'));
 }
 onFileUploadClick(event): void {
   if(event.target){
     event.target.value=null;
   }
 }

 displayCountsForReviewPage() {
  if (this.notificationRecipientsFormGroup.value.recipientListSource.code == 'IMPRT') {
    if (!this.displayCountsMessageForImprt) {
      this.notificationService.previewRecipientsBS.next(null);
      this.recipientFiltersService.previewRecipientsBS.next(null);    
    } 
  } else if (this.notificationRecipientsFormGroup.value.recipientListSource.code == 'EXTFL') {
    if (!this.displayCountsMessageForExtfl) {
      this.notificationService.previewRecipientsBS.next(null);
      this.recipientFiltersService.previewRecipientsBS.next(null);                
    } 
  }
}

 onFileInput(event): void {
  const fullPath =this.notificationRecipientsFormGroup.value.fileInput;
    if (fullPath) {
        const startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
        let filenameV = fullPath.substring(startIndex);        
        if ( filenameV.indexOf('\\') === 0 ||  filenameV.indexOf('/') === 0) {
          filenameV = filenameV.substring(1);
        }
      this.notificationRecipientsFormGroup.patchValue({fileName: filenameV}) ;
      if(this.isValidFile(filenameV)){
            if(event.target.files && event.target.files.length) {
              const [file] = event.target.files;
              this.fileToUpload = file;
              this.notificationService.currentNotification.recipients = this.notificationRecipientsFormGroup.value;
              this.notificationService.currentNotification.recipients.employeeIds = [];
              if(filenameV.endsWith('.csv')){
                this.readFromCSVAndUpload(this.fileToUpload);
              }else if (filenameV.endsWith('.xlsx') || filenameV.endsWith('.xlxs')){
                let employeeIdArray: string[] = [];
                readXlsxFile(file).then((data) =>{
                        this.uploadCount =data.length;
                        if(this.uploadCount > 0){
                          this.notificationService.currentNotification.recipients.uploadCount = data.length;
                          data.map(dataElement => {
                            this.notificationService.currentNotification.recipients.employeeIds.push({ empId: dataElement[0], compCode: dataElement[1] });
                          });
                          this.uploadRecipients();
                        } else {
                          this.notificationRecipientsFormGroup.get('fileName').setErrors({'noRecords':true});
                        }
                }).catch(error => {
                    // console.log(error);
                });
              }
          }
      } else {
        this.notificationRecipientsFormGroup.get('fileName').setErrors({'invalidFile':true});
        this.validCount = 0;
        this.uploadCount = 0;
      }

  }

  this.onChange(event);
}

uploadRecipients() {
  this.notificationService.uploadRecipients().subscribe(data => {
        this.displayCountsMessageForImprt=false;
        this.notificationService.currentNotification = data;
        this.uploadCount=this.notificationService.currentNotification.recipients.uploadCount;
        this.validCount=this.notificationService.currentNotification.recipients.validCount; 
    }, error =>{
        // console.log("Error in the Step 3");
        //TO DO show an error message on the screen;
        this.notificationRecipientsFormGroup.patchValue({fileName: null}) ;
    });
}

readFromCSVAndUpload(file: any) {
    var reader = new FileReader();
    reader.readAsText(file);
    
    reader.onload =  (data) => {
        let csvData =(<FileReader>data.target ).result;
        let strCsvData = (<string>csvData);
        let csvRecordsArray = strCsvData.split(/\r\n|\n/);
        if (csvRecordsArray.length > 1) {
          for (let i = 1; i < csvRecordsArray.length; i++) {
            let data = (<string>csvRecordsArray[i]).split(',');
            if (data[0].trim() != "") {
              let empIdAndCompanyCode = data[0] + '~' + data[1];
              this.notificationService.currentNotification.recipients.employeeIds.push({ empId: data[0], compCode: data[1] });
            }
          }
        }
        if(this.notificationService.currentNotification.recipients.employeeIds && this.notificationService.currentNotification.recipients.employeeIds.length > 0){
            this.uploadRecipients();
          }else{
            this.notificationRecipientsFormGroup.get('fileName').setErrors({'noRecords':true});
          }
    }

    reader.onerror = function() {
          // console.log('unable to read the file', file);
    };
  }

  private validateAllFormFields(formGroup: FormGroup): void {
      Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
              control.markAsTouched({ onlySelf: true });
          } else if (control instanceof FormGroup) {
              this.validateAllFormFields(control);
          }
      });
  }
  previewRecipients() {
    if (this.notificationRecipientsFormGroup.value.recipientListSource.code == 'EXTFL') {          
      this.existingFilterId = this.notificationRecipientsFormGroup.value.existingFilters.id;
    }
    this.dialogRefPreviewRecipients = this.dialog.open(PreviewRecipientsDialogComponent,{
      disableClose: true,
      data: {
        notificationId: this.notificationService.currentNotification.id,
        filterId: this.existingFilterId
      }
    });
  }
  showFilterCreteria() {
    if (this.notificationRecipientsFormGroup.value.recipientListSource.code == 'EXTFL') {          
      this.existingFilterId = this.notificationRecipientsFormGroup.value.existingFilters.id;
    }

    this.dialogRefFilterCriteria = this.dialog.open(FilterCriteriaDialogComponent,{
      width: "45%",
      disableClose: true,
      data: {
        notificationId: this.notificationService.currentNotification.id,
        filterId: this.existingFilterId
      }
    });
  }

  onRecipientListSourceChange(event): void { 
    this.notificationRecipientsFormGroup.patchValue({fileName: ''}) ;
    this.existingFilterId = 0;         
    if (this.notificationService.currentNotification.recipients) {
      this.notificationService.currentNotification.recipients.existingFilters = null;
    }

    if (!this.recipientListSourceFlag) {
      this.recipientListSourceFlag = true;
    } else {
      this.warningRecipientListChange = "You have changed the recipient method. If you continue, you will lose existing recipients.";       
      this.notificationRecipientsFormGroup.patchValue({fileInput: null}) ;             
    }
    
    this.onChange(event);
  }  
    
  onChange($event) {
    this.notificationService.setDirty(true);
  }  

  openCsvDialog() {
    this.dialogImportInfo = this.dialog.open(NotificationImportInfoComponent, {
      width: "35%"
    });
  }

  compareFn(c1: any, c2: any): boolean {
    return JSON.stringify(c1) === JSON.stringify(c2);
  }
}